// GEOCACHE INDIVIDUAL LAYOUT
@use 'vars' as *;
.geocache-heading {
    font-family: $bodyFont;
    font-size: 1.3125rem;
    font-weight: 600;
  }
  
  .geocache-data-wrapper {
    display: flex;
    justify-content: space-between;
    padding-left: 0;

    .geocache-data {
      padding-right: 1rem;
    }
  
    ul {
      list-style-type: none;
      margin-top: 0;
      padding: 0;
    }
  
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
  
  .geocache-map iframe {
    border: 1px solid #000;
    height: 300px;
    width: 400px;
  }
  
  .map {
    width: 100%; 
    height: 100%; 
    position: relative;
    z-index: 100;
  }

  .map-wrapper {
    width: 450px; 
    height: 320px;
    float: right;
  }

  .tracklog-map {
    border: 1px solid #000;
    min-height: 500px;
    width: 100%; 
  }

  .track-info {
    border: 1px solid black;
    background: #fff;
    padding: .5rem;

    th {
      padding-left: 0;
      font-size: .9rem;
      font-weight: bold;
      text-align: left;
    }
  }

  article .leaflet-control a {
    border: 0;
  }

  .leaflet-control-layers-list {
    padding: 0;
  }

  // ACCORDION (for Geocaches)
  
  .accordionItem {
    border-bottom: 1px solid #ddd;
  
    h3 {
      color: #246193;
      font-family: $bodyFont;
      font-size: 1.125rem;
      font-weight: 400;
      margin: 0;
      padding: 0.4em;
  
      &:hover {
        cursor: pointer;
      }
    }
  
    >div {
      margin: 0;
      padding: 1em 0.4em;
    }
  
    &.hide {
      div {
        display: none;
      }
    }
  }

  // TABS (for Geocache logs)

.invisible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  .example-tabs-tabs__list {
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #ddd;
  }
  
  .example-tabs-tabs__item {
    display: inline-block;
  }
  
  .example-tabs-tabs__link {
    display: inline-block;
    padding: 0.5rem 1rem;
    background: transparent;
    cursor: pointer;
    color: #148297;
  }
  
  [aria-selected="true"].example-tabs-tabs__link {
    color: #000;
    border: 1px solid #ccc;
    border-bottom: 1px solid #fff;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    margin-bottom: -1px;
  
    &:hover {
      border: 1px solid #ccc;
      border-bottom: 1px solid #fff;
    }
  }
  
  [aria-selected="false"].example-tabs-tabs__link {
    border: 1px solid transparent;
    border-bottom: 0;
  
    &:hover {
      border: 1px solid #ccc;
      border-bottom: 0;
    }
  }
  
  .example-tabs-tabs__content {
    background: transparent;
    padding: 1em;
  }
  
  [aria-hidden="true"].example-tabs-tabs__content {
    display: none;
  }
  
  .js-tablist__item a {
    border: 0;
  
    &:hover {
      border: 0;
    }
  }
  