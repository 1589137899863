@use 'sass:color';
@use 'vars' as *;
html {
  scroll-behavior: smooth;
  scroll-padding-top: $headerHeight; /* height of sticky header */
}

body {
  background: $bodyBackgroundColor;
  box-sizing: border-box;
  color: $bodyColor;
  font-family: $bodyFont;
  font-size: $bodyFontSize;
  font-weight: $bodyFontWeight;
  line-height: 1.6;
  margin: 0;
  padding-top: 100px;
  overflow-y: auto;
  scrollbar-gutter: stable;

  &.nav-visible {
    // height: 100%; 
    height: 80vh;
    overflow: hidden;
  }
}

.wrapper {
  height: $headerHeight;
  position: fixed;
  top: 0;
  width: 100%;    
  z-index: 400;

  &.active {
    background-color: rgba(42,52,65,0.95);
    transition: all 400ms ease;
  }
}

#masthead {
  margin: 0;
  padding: 0 8px;
  
  .fa-search {
    color: #efefef;
    margin-top: 28px;
  }

  .search-toggle {
    display: flex; 
    font-size: 1.8rem; 
    justify-content: flex-end; 
    flex: 1; 
    margin-right: 24px; 
    z-index: 120;

    .nav-visible & {
      background-color: #000;
    }
  }

  .super-search {

    &__input {
      color: #999;
      font-size: 3rem;
      height: $headerHeight;
    }

    &__close-btn {
      color: $bodyBackgroundColor;
      font-size: 3rem;
      right: 24px;
      top: 12px;
    }
  }
}

main {  
  clear: both;
  max-width: 1074px;
  margin: 0 auto;
  padding: 1.15em;  

  section {
    background: $innerBackgroundColor;
    margin: 0 auto;
    padding: 3rem 5.263rem;

    @media (max-width: 768px) {
      padding: 3rem;
    }
  }
}

.excerpt p {
  display: inline;
}

.featured-image {
  a,
  a:hover,
  a:focus,
  a:active {
    border: 0;
  }
  img {
    height: auto;
    margin: 1rem 0;
    max-width: 100%;

    @media (max-width: 768px) {
      margin: .6rem 0;
    }
  }
}

.inside-header {
  display: flex;
  height: $headerHeight;
  margin: 0 auto;
  max-width: 1080px;
}

.site-title {
  float: left;
  z-index: 110;

  a {
    color: $lightHeadingColor;
    font-size: 2rem;
    text-decoration: none;

    @media (max-width: 768px) {
      font-size: 1.4rem;
    }    
  }
}

.site-info {
  color: $footerColor;
  padding: 2rem;
  text-align: center;
}

#scroll-to-top {
  background-color: rgba( 0,0,0,0.4 ); 
  border: none;
  border-radius: 3px;
  bottom: 20px;
  color: white;
  cursor: pointer;
  display: none; 
  font-size: 18px;
  font-weight: 700;
  outline: none; 
  padding: 12px 12px 7px 12px; 
  position: fixed;    
  right: 30px; 
  transition: all 3s ease-in-out;
  z-index: 99;

  &:hover {
    background-color: rgba( 0,0,0,0.6 );
  }
}

h1 {
  color: $bodyColor;
  font-family: $headingFont;
  font-size: 1.75rem;
  font-weight: 700;
}

h2 {
  font-family: $headingFont;
  font-size: 1.375rem;
  font-weight: 700;
}

em,
i,
cite {
  @include italic-text;
}

blockquote {  
  @include italic-text;
  font-size: 1.09rem;
  line-height: 1.625rem;
  margin: 28px 0 28px;
  padding-bottom: 0;
  padding-top: 0;
  border-left: 5px solid #d1dce5;
  padding: 0 20px;
}

small {
  font-size: 1.1rem;

  a {
    color: #fff;
    text-decoration: none
  }

  ;
}

.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.emoji {
  vertical-align: text-bottom;
}

.float-left {
  float: left;
  margin-right: 1rem;
}

// SINGLE POST LAYOUT

.post-navigation {
  background-color: transparent;
  margin: 0 auto;
  max-width: 1050px;
  padding: 1rem 0;
  font-weight: bold;

  a {
    color: #fff;
    text-decoration: none;

    &:hover {
      border-bottom: 1px dashed white;
    }
  }

  .prev-post {
    &::before {
      content: "\02C2";
      margin-right: 0.5rem;
    }
  }

  .next-post {
    &::after {
      content: "\02C3";
      margin-left: 0.5rem;
    }
  }

  a:hover::before {
    text-decoration: none;
  }
}

// POST META

.entry-header {
  color: #595959;
  display: flex;
  margin-bottom: 4rem;

  @media (max-width: 768px) {
    display: block;
    margin-bottom: 0;
  }

  .cat-links {
    font-family: Muli, Arial;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;

    a {
      border: 0;
      color: #595959;
      text-decoration: none;

      &:hover {
        color: #1e73be;
      }
    }
  }

  h1 {
    margin: 0;

    &.entry-title {
      @media (max-width: 768px) {
        clear: both;
        margin-top: 2rem;
      }
    }
  }

  .byline {
    font-size: 0.875rem;
  }
}

.post-date {
  background-color: #fff;
  border-bottom: solid 5px #d1dee9;
  border-radius: 38px;
  border-top: solid 5px #d1dee9;
  float: left;
  margin: 5px 24px 5px 0;
  padding: 10px;
  text-align: center;
  width: 52px;

  .month {
    background-image: none;
    box-shadow: none;
    display: block;
    font: 11px/100% Arial, Helvetica, sans-serif;
    letter-spacing: 1px;
    padding: 2px 0;
    text-transform: uppercase;
    padding: 2px 0;
    position: relative;
    top: -1px;
    width: 100%;
    border-radius: 10px;
  }

  .day {
    color: $bodyBackgroundColor;
    display: block;
    font: bold 24px/100% Arial, Helvetica, sans-serif;
    padding: 2px 0 4px;
  }

  .year {
    color: #666;
    display: block;
    font: 14px/100% Arial, Helvetica, sans-serif;
    padding: 0 0 4px;
  }
}

// ARTICLE/ENTRY CONTENT

article a {
  border-bottom: 1px dashed #80a5c5;
  color: #1e73be;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    border-bottom: 1px solid #018ed3;
    color: #018ed3;
    text-decoration: none;
  }

  &.button {
    background: #666666;
    border-bottom: 0;
    color: #fff;
    display: inline-block;
    padding: 10px 20px;

    &:hover,
    &:active,
    &:focus {
      background-color: #3f3f3f;
    }
  }
}

.history-aside {
  background-color: #efefef;
  border-bottom: 2px solid gray;
  border-top: 1px solid gray;
  margin: 2.5rem 0;
  padding: 1rem 2rem;
}

// POST COMMENTS (Giscus)

section.comments {
  background-color: rgba(255, 255, 255, 0.7);
}

.comment-reply-header {
  font-size: 1rem;
  text-transform: uppercase;
}


// SURVEY MARK LIST

.surveymark-heading {
  font-family: $bodyFont;
  font-size: 1.3125rem;
  font-weight: 600;
}

.surveymark-list {
  margin: 1em 0;
  list-style-type: none;
  padding-left: 8px;

  li {
    border-bottom: 1px solid #0000001a;
    line-height: 2.2;

    a {
      border: 0;
    }
  }

  .fa-arrow-up-right-from-square {
    color: #4a96d9;
    font-size: 80%;
    margin-left: 0.5rem;
  }
}



// NAVIGATION
//== Variables
//
$color-white: #fff;
$color-white-darker: color.adjust($color-white, $lightness: -5%);
$color-white-darken: color.adjust($color-white, $lightness: -15%);
$header-height: $headerHeight;
$nav-toggle-size: 2rem;
$nav-toggle-transition-speed: .3s;
$nav-transition-speed: .6s;
$nav-width: 100vw;
$zindex-header: 500;

.navhead {
  height: $header-height;
  margin-top: 1rem;
  position: relative;
  text-align: right;
  top: 0;
  float: right;
  transition: transform $nav-transition-speed;
  z-index: $zindex-header;
}

.container {
  margin: 0 auto;
  width: 80%;
}

.nav-button {
  text-transform: uppercase;
}

.nav-wrapper {  
  background-color: #000;
  height: 100vh;
  margin-top: 80px;
  margin-left: 0;
  margin-right: 0;
  perspective: 1000px;
  position: fixed;
  right: 0;  
  scrollbar-gutter: stable;
  top: 0;
  visibility: hidden;
  width: $nav-width;
  overflow-y: auto;
  z-index: 100;

  .nav-visible & {
    height: 100vh;
    transform: translateX(0);  
    visibility: visible;
  }
}

.nav-visible {
  #masthead {
    background-color: black;
    position: sticky;
    top: 0;
    z-index: 120;
  }
}

.nav {
  position: relative;
  transform: rotateY(90deg);
  transform-origin: left center;
  transition: transform $nav-transition-speed;
  
  padding-top: 100px;

  .nav-visible & {
    transform: rotateY(0deg);
  }

  li {
    &.active {
      a {
        background-color: #212635;
      }
    }
  }

  a {
    display: block;
    line-height: 1.4;
    padding: 0.7em 0.5em 0.7em 1.4em;
    text-transform: uppercase;

    &:focus,
    &:hover {
      text-decoration: none;
    }
  }
}

//== Nav active marker
//
.nav-marker {
  background-color: currentColor;
  height: 2.8rem;
  position: absolute;
  right: 0;
  top: $header-height;
  width: 3px;

  &:before {
    border: 5px solid transparent;
    border-right-color: inherit;
    content: "";
    height: 0;
    position: absolute;
    right: 3px;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
  }
}

.color-1 {
  color: deeppink;
}

//== Menu toggle (hamburger)
//
@mixin button-lines {
  background: $color-white;
  border-radius: calc(2rem / 10);
  display: inline-block;
  height: calc(2rem / 10);
  width: 2rem;
}

.button-lines {
  background: transparent;
  border: none;
  cursor: pointer;
  display: inline-block;
  padding: calc(2rem / 2) calc(2rem / 4);
  user-select: none;
}

.lines {
  @include button-lines;
  position: relative;

  &:after,
  &:before {
    @include button-lines;
    content: "";
    left: 0;
    position: absolute;
  }

  &:after {
    top: calc(2rem / 4);
  }

  &:before {
    top: calc(0px - 2rem / 4);
  }
}

.button-lines-x {
  .lines {
    transition: background .3s ease;

    &:after,
    &:before {
      transform-origin: 50% 50% 0;
      transition: top .3s .4s ease, transform .3s ease;
    }
  }

  .nav-visible & {
    .lines {
      background: transparent;

      &:after,
      &:before {
        top: 0;
        transition: top .3s ease, transform .3s .3s ease;
      }

      &:after {
        transform: rotate3d(0, 0, 1, 45deg);
      }

      &:before {
        transform: rotate3d(0, 0, 1, -45deg);
      }
    }
  }
}


/* Accordion menu */

ul.menu {  
  padding: 0;
  list-style: none;
  width: 100%;
  margin: 20px auto;
  font-family: $bodyFont;
  clear: both;
  display: table;
  text-align: center;
  width: 25vw;


  h2 {
    color: #efefef;
    font-family: $bodyFont;
  }

  @media (max-width: 768px) {
    width: 100vw;
  }
}

ul.menu .list,
ul.menu button {
  font-size: 1.2rem;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  line-height: 29px;
  clear: both;
}

ul.menu .list.submenu button:after {
  content: "\f107";
  font-family: FontAwesome;
  position: relative;
  left: 10px;
  top: 0;
  color: #fff;
}

ul.menu .list.submenu button {
  background-color: transparent;
  border: 0;
  color: #fff;
  font-family: $bodyFont;

  &:hover {
    background-color: #3f3f3f;
    transition: 300ms all;
    cursor: pointer;
  }

  &.active {
    background-color: #3f3f3f;
  }
}

ul.menu .list a {
  animation: fadeIn 5s;
  text-decoration: none;
  color: #fff;
  padding: 0.2rem;
  display: block;
  height: 100%;
  box-sizing: border-box;
  
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
}

ul.menu .list a:hover {
  background-color: #3f3f3f;
  transition: 300ms all;
}

ul.menu .list .items {
  height: 0px;
  overflow: hidden;
}

ul.menu .list .items a {
  padding: 0;
}

ul.menu .list .items a:hover {
  background-color: #4f4f4f;
  color: #fff;
  transition: 300ms all;
}

ul.menu .list:last-child {
  border-bottom: none;
}

ul.menu .submenu.active button:after {
  content: "\f106";
  font-family: FontAwesome;
  position: relative;
  left: 10px;
  color: #fff;
}

ul.menu .active>.items {
  display: block;
  background: transparent;
  padding: 0px;
  height: auto;
  color: #fff;
  transition: all 200ms;
  clear: both;
  float: left;
  width: 100%;
}

ul.menu .active>.items li {
  padding: 0px;
  list-style: none;
}

ul.menu .active>.items li:last-child {
  border-color: transparent;
  padding-bottom: 0px;
}

ul.menu .active>.items .active>.items {
  background-color: #2f4b67;
}

ul.menu .active .list {
  background: #697d92;
}

ul.menu .active .list a {
  padding: 17px 0px 17px 45px;
}


// PAGINATION

.pagination {
  font-size: .8rem;
  font-weight: bold;
  text-align: center;

  a:first-child,
  a:last-child {
    border: 0;
    background-color: transparent;
    color: #f0f8ff;
    font-size: 1rem;

    &:hover {
      background-color: transparent;
      color: #fff;
    }
  }

  a {
    background-color: #d1dde6;
    border: 1px solid #fff;
    border-radius: 20px;
    color: $bodyBackgroundColor;
    padding: 8px 12px;
    text-decoration: none;

    &:hover {
      background-color: #fff;
    }
  }

  span {
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 20px;
    padding: 8px 12px;
  }
}