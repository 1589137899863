$bodyBackgroundColor: #60829e;
$bodyColor: #6f6361;
$lightHeadingColor: #efefef;
$innerBackgroundColor: #fff;
$footerColor: #fff;

$bodyFont: Mulish, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$bodyFontSize: 1.125rem;
$bodyFontWeight: 400;
$headingFont: Merriweather,'Handlee', cursive;

$headerHeight: 80px;


@mixin italic-text {
  font-family: Lora, serif;
  font-style: italic;
}